<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :mask-closable="false"
      :closable="false"
      title="挽联信息"
    >
      <template slot="footer">
        <a-button
          class="cancel-btn"
          @click="closeModal"
          style="font-size: 14px;"
        >
          关闭
        </a-button>
      </template>

      <div
        v-for="(item, index) in data"
        :key="index"
      >
        <a-divider v-if="index > 0" />
        <a-descriptions
          :column="1"
          class="custom-descriptions-max-7 custom-descriptions"
        >
          <a-descriptions-item label="逝者姓名">
            {{ item.dead_name }}
          </a-descriptions-item>
          <a-descriptions-item label="逝者性别">
            {{ item.sex }}
          </a-descriptions-item>
          <a-descriptions-item label="与逝者关系">
            {{ item.relationship }}
          </a-descriptions-item>
          <a-descriptions-item label="称呼逝者">
            {{ item.call }}
          </a-descriptions-item>
          <a-descriptions-item label="亲属方">
            {{ item.family_text }}
          </a-descriptions-item>
          <a-descriptions-item label="敬赠人(男)">
            {{ item.send_man_name }}
          </a-descriptions-item>
          <a-descriptions-item label="敬赠人(女)">
            {{ item.send_woman_name }}
          </a-descriptions-item>
          <a-descriptions-item v-if="item.type">
            {{ item.members_name }}
          </a-descriptions-item>
          <a-descriptions-item label="挽联内容">
            {{ item.content }}
          </a-descriptions-item>
        </a-descriptions>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'ElegiacCouplet',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    closeModal() {
      this.isShow = false
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .ant-modal-content {
  background-color: #2a4c39;
}

/deep/ .ant-modal-header {
  border-bottom: 1px solid #848484;
  background-color: #2a4c39 !important;

  .ant-modal-title {
    font-size: 20px;
    color: @white !important;
  }
}

/deep/ .ant-modal-body {
  color: @white;
}

/deep/ .ant-modal-footer {
  border-top: none;
  text-align: center;
  background-color: #2a4c39 !important;
}

.cancel-btn {
  width: 150px;
  font-size: 20px !important;
  border: none;
}

/deep/ .submit-btn.ant-btn:hover {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .submit-btn.ant-btn:focus {
  color: rgba(0, 0, 0, 65%);
}

/deep/ .ant-divider-horizontal {
  background-color: #848484;
}
</style>
